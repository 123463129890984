export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
    action: 'read',
    resource: 'Auth',
  },
  {
    title: 'Relatórios',
    icon: 'ListIcon',
    children: [
      {
        title: 'Geral',
        icon: 'ListIcon',
        route: 'reports',
        action: 'manage',
        resource: 'reports',
      },
    ],
  },
  {
    title: 'Clientes',
    icon: 'UserIcon',
    children: [
      {
        title: 'Listar',
        icon: 'ListIcon',
        route: 'clients-list',
        action: 'read',
        resource: 'client',
      },
      {
        title: 'Grupos',
        icon: 'ListIcon',
        children: [
          {
            title: 'Listar',
            icon: 'ListIcon',
            route: 'user-groups-list',
            action: 'read',
            resource: 'client-groups',
          },
          {
            title: 'Criar',
            icon: 'ListIcon',
            route: 'user-groups-create',
            action: 'edit',
            resource: 'client-groups',
          },
        ],
      },
    ],
  },
  {
    title: 'Transações',
    icon: 'DollarSignIcon',
    children: [
      {
        title: 'Listar Saques',
        icon: 'DollarSignIcon',
        route: 'withdraws-list',
        action: 'read',
        resource: 'withdrawals',
      },
      {
        title: 'Listar Depositos',
        icon: 'DollarSignIcon',
        route: 'deposits-list',
        action: 'read',
        resource: 'deposits',
      },
      {
        title: 'Consolidado',
        icon: 'DollarSignIcon',
        route: 'consolidated',
        action: 'manage',
        resource: 'financial-reports',
      },
      {
        title: 'GGR Consolidado',
        icon: 'DollarSignIcon',
        route: 'ggr-consolidated',
        action: 'manage',
        resource: 'financial-reports',
      },
      {
        title: 'FTD',
        icon: 'DollarSignIcon',
        route: 'ftd',
        action: 'manage',
        resource: 'financial-reports',
      },
    ],
  },
  {
    title: 'Promoções',
    icon: 'MessageCircleIcon',
    children: [
      {
        title: 'Cadastrar',
        icon: 'ListIcon',
        route: 'promotion-create',
        action: 'edit',
        resource: 'client-promotions',
      },
      {
        title: 'Listar Ativas',
        icon: 'ListIcon',
        route: 'user-promotions-list',
        action: 'read',
        resource: 'client-promotions',
      },
      {
        title: 'Brincadeiras',
        icon: 'ListIcon',
        route: 'users-playtime',
        action: 'read',
        resource: 'client-promotions',
      },
    ],
  },
  {
    title: 'Menu Master',
    icon: 'UserIcon',
    action: 'be',
    resource: 'company-partner',
    children: [
      {
        title: 'Listar Clientes',
        icon: 'ListIcon',
        route: 'master-clients-list',
        action: 'read',
        resource: 'client',
      },
      {
        title: 'Listar Transações',
        icon: 'DollarSignIcon',
        route: 'master-transactions',
        action: 'read',
        resource: 'transactions',
      },
    ],
  },
  {
    title: 'Jogos',
    icon: 'PlayIcon',
    children: [
      {
        title: 'Listar',
        icon: 'ListIcon',
        route: 'games-list',
        action: 'read',
        resource: 'games',
      },
      {
        title: 'Grupos',
        icon: 'ListIcon',
        children: [
          {
            title: 'Listar',
            icon: 'ListIcon',
            route: 'games-group-list',
            action: 'read',
            resource: 'game-groups',
          },
          {
            title: 'Criar',
            icon: 'ListIcon',
            route: 'game-group-single',
            action: 'create',
            resource: 'game-groups',
          },
        ],
      },
    ],
  },
  {
    title: 'Sair',
    icon: 'LogOutIcon',
    route: 'logout',
  },
  // {
  //   title: 'Afiliados',
  //   icon: 'UsersIcon',
  //   children:[
  //     {
  //       title: 'Listar Ativos',
  //       icon: 'ListIcon',
  //     },
  //     {
  //       title: 'Cadastrar',
  //       icon: 'ListIcon',
  //     },
  //     {
  //       title: 'Listar todos',
  //       icon: 'ListIcon',
  //     },
  //   ]
  // },
  // {
  //   title: 'Relatórios',
  //   icon: 'TrendingUpIcon',
  //   children:[
  //     {
  //       title: 'Movimento de jogos',
  //       icon: 'ListIcon',
  //     },
  //     {
  //       title: 'Entradas e saídas',
  //       icon: 'ListIcon',
  //     },
  //   ]
  // },
  // {
  //   title: 'Usuários Adm',
  //   icon: 'UserCheckIcon',
  //   children:[
  //     {
  //       title: 'Listar Ativos',
  //       icon: 'ListIcon',
  //     },
  //     {
  //       title: 'Cadastrar',
  //       icon: 'ListIcon',
  //     },
  //     {
  //       title: 'Listar todos',
  //       icon: 'ListIcon',
  //     },
  //   ]
  // },
  // {
  //   title: 'Funcionárops',
  //   icon: 'BriefcaseIcon',
  //   children:[
  //     {
  //       title: 'Listar Ativos',
  //       icon: 'ListIcon',
  //     },
  //     {
  //       title: 'Cadastrar',
  //       icon: 'ListIcon',
  //     },
  //     {
  //       title: 'Listar todos',
  //       icon: 'ListIcon',
  //     },
  //   ]
  // },

  // {
  //   title: 'Roulette',
  //   icon: 'UserIcon',
  //   children:[
  //     {
  //       title: 'Rondas',
  //       icon: 'ListIcon',
  //       route: 'rounds-list',
  //       action: 'be',
  //       resource: 'company-employee'
  //     },
  //   ]
  // },

  // {
  //   title: 'Chat',
  //   icon: 'UserIcon',
  //   children:[
  //     {
  //       title: 'Rooms',
  //       icon: 'ListIcon',
  //       route: 'rooms-list',
  //       action: 'be',
  //       resource: 'company-employee'
  //     }
  //   ]
  // },
]
